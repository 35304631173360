import {

    createMemoryHistory,
    createRouter,

} from 'vue-router'


const routes = [{
        path: '/',
        name: 'home',
        component: () =>
            import ('../views/LoginView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/LoginView.vue')
    },
    {
        path: '/user.do',
        name: 'main',
        component: () =>
            import ('../views/MainView.vue'),
        children: [{
                path: '/user.do',
                name: 'cesuan1',

                component: () =>
                    import ('../views/CeSuan.vue'),
            },
            {
                path: '/user.do',
                name: 'cesuan2',

                component: () =>
                    import ('../views/CeSuan.vue'),
            },
            {
                path: '/user.do/cesuan',
                name: 'cesuan',

                component: () =>
                    import ('../views/CeSuan.vue'),
            },
            {
                path: '/user.do/chengben',
                name: 'chengben',

                component: () =>
                    import ('../views/ChengBen.vue'),
            },
            {
                path: '/user.do/setup',
                name: 'setup',
                component: () =>
                    import ('../views/SetupView.vue'),
                children: [{
                        path: '/user.do/setup',
                        name: 'cpgl',
                        component: () =>
                            import ('../views/Setup/CpglView.vue'),
                    },
                    {
                        path: '/user.do/setup/playtype',
                        name: 'playtype',
                        component: () =>
                            import ('../views/Setup/PlayType.vue'),
                    },
                    {
                        path: '/user.do/setup/lampMessage',
                        name: 'lampMessage',
                        component: () =>
                            import ('../views/Setup/LampMessage.vue'),
                    },
                    {
                        path: '/user.do/setup/danSearch',
                        name: 'danSearch',
                        component: () =>
                            import ('../views/Setup/DanSearch.vue'),
                    },
                    {
                        path: '/user.do/setup/record',
                        name: 'record',
                        component: () =>
                            import ('../views/Setup/RecordView.vue'),
                    },
                    {
                        path: '/user.do/setup/animal',
                        name: 'animal',
                        component: () =>
                            import ('../views/Setup/AnimalView.vue'),
                    },
                    {
                        path: '/user.do/setup/zhuxiao',
                        name: 'zhuxiao',
                        component: () =>
                            import ('../views/Setup/ZhuDanZhuXiao.vue'),
                    },


                    {
                        path: '/user.do/setup/tomoren',
                        name: 'tomoren',
                        component: () =>
                            import ('../views/Setup/FanShui.vue'),
                    },
                    {
                        path: '/user.do/setup/pancha',
                        name: 'pancha',
                        component: () =>
                            import ('../views/Setup/PanCha.vue'),
                    },
                ]
            },
            {
                path: '/user.do/dgd',
                name: 'dgd2',
                component: () =>
                    import ('../views/User/DgdView.vue'),
            },
            {
                path: '/user.do/customer',
                name: 'customer',
                component: () =>
                    import ('../views/User/CustomerView.vue'),
            },
            {
                path: '/user.do/operate',
                name: 'operate',
                component: () =>
                    import ('../views/OperateView.vue'),
                children: [{
                        path: '/user.do',
                        name: 'tm4',
                        component: () =>
                            import ('../views/ProfitView.vue'),
                    },
                    {
                        path: '/',
                        name: 'tm3',
                        component: () =>
                            import ('../views/ProfitView.vue'),
                    },
                    {
                        path: '/user.do/oprate/detail',
                        name: 'detail',
                        component: () =>
                            import ('../views/ProfitDetail.vue'),
                    },
                    {
                        path: '/user.do/operate',
                        name: 'tm2',
                        component: () =>
                            import ('../views/ProfitView.vue'),
                    },

                    {
                        path: '/user.do/operate/tm',
                        name: 'tm',
                        component: () =>
                            import ('../views/ProfitView.vue'),
                    },
                    {
                        path: '/user.do/operate/zm',
                        name: 'zm',
                        component: () =>
                            import ('../views/Operate/ZmView.vue'),
                    },
                    {
                        path: '/user.do/operate/lm',
                        name: 'lm',
                        component: () =>
                            import ('../views/Operate/LmView.vue'),
                    },

                    {
                        path: '/user.do/operate/z1t',
                        name: 'z1t',
                        component: () =>
                            import ('../views/Operate/ZntView.vue'),
                    },
                    {
                        path: '/user.do/operate/z2t',
                        name: 'z2t',
                        component: () =>
                            import ('../views/Operate/ZntView.vue'),
                    },

                    {
                        path: '/user.do/operate/z3t',
                        name: 'z3t',
                        component: () =>
                            import ('../views/Operate/ZntView.vue'),
                    },
                    {
                        path: '/user.do/operate/z4t',
                        name: 'z4t',
                        component: () =>
                            import ('../views/Operate/ZntView.vue'),
                    },
                    {
                        path: '/user.do/operate/z5t',
                        name: 'z5t',
                        component: () =>
                            import ('../views/Operate/ZntView.vue'),
                    },
                    {
                        path: '/user.do/operate/z6t',
                        name: 'z6t',
                        component: () =>
                            import ('../views/Operate/ZntView.vue'),
                    },
                    {
                        path: '/user.do/operate/bb',
                        name: 'bb',
                        component: () =>
                            import ('../views/Operate/BanBo.vue'),
                    },
                    {
                        path: '/user.do/operate/tx',
                        name: 'tx',
                        component: () =>
                            import ('../views/Operate/TxView.vue'),
                    },

                    {
                        path: '/user.do/operate/bz',
                        name: 'bz',
                        component: () =>
                            import ('../views/Operate/BzView.vue'),
                    },
                    {
                        path: '/user.do/operate/lx',
                        name: 'lx',
                        component: () =>
                            import ('../views/Operate/LxView.vue'),
                    },
                    {
                        path: '/user.do/operate/dx',
                        name: 'dx',
                        component: () =>
                            import ('../views/Operate/LianX.vue'),
                    },
                    {
                        path: '/user.do/operate/dw',
                        name: 'dw',
                        component: () =>
                            import ('../views/Operate/LwView.vue'),
                    },
                    {
                        path: '/user.do/operate/xw',
                        name: 'xw',
                        component: () =>
                            import ('../views/Operate/XwView.vue'),
                    },

                    {
                        path: '/user.do/operate/oper',
                        name: 'oper',
                        component: () =>
                            import ('../views/Operate/ZhuDan.vue'),
                    },





                ]
            },
            {
                path: '/user.do/tmzx',
                name: 'tmzx',
                component: () =>
                    import ('../views/TmzxView.vue'),

            },
            {
                path: '/user.do/user',
                name: 'user',
                component: () =>
                    import ('../views/UserView.vue'),
                children: [{
                        path: '/user.do/user',
                        name: 'user',
                        component: () =>
                            import ('../views/User/DgdView.vue'),
                    },
                    {
                        path: '/user.do/user/empty',
                        name: 'empty',
                        component: () =>
                            import ('../views/User/EmptyView.vue'),
                    },
                    {
                        path: '/user.do/user/dgd',
                        name: 'dgd',
                        component: () =>
                            import ('../views/User/DgdView.vue'),
                    },
                    // {
                    //     path: '/user.do/user/gd',
                    //     name: 'gd',
                    //     component: () =>
                    //         import ('../views/User/GdView.vue'),
                    // },
                    // {
                    //     path: '/user.do/user/zd',
                    //     name: 'zd',
                    //     component: () =>
                    //         import ('../views/User/ZdView.vue'),
                    // },
                    // {
                    //     path: '/user.do/user/dl',
                    //     name: 'dl',
                    //     component: () =>
                    //         import ('../views/User/DlView.vue'),
                    // },
                    // {
                    //     path: '/user.do/user/hy',
                    //     name: 'hy',
                    //     component: () =>
                    //         import ('../views/User/HyView.vue'),
                    // },
                    {
                        path: '/user.do/user/Zshy',
                        name: 'Zshy',
                        component: () =>
                            import ('../views/User/ZshyView.vue'),
                    },
                    {
                        path: '/user.do/user/cpz',
                        name: 'cpz',
                        component: () =>
                            import ('../views/User/CaoPan.vue'),
                    },
                    {
                        path: '/user.do/user/bhhy',
                        name: 'buhuao',
                        component: () =>
                            import ('../views/User/BuHuo.vue'),
                    },
                    {
                        path: '/user.do/user/zzh',
                        name: 'zizhanghao',
                        component: () =>
                            import ('../views/User/ZiZhangHao.vue'),
                    },



                    // {
                    //     path: '/user.do/setup/tomoren',
                    //     name: 'tomoren',
                    //     component: () =>
                    //         import ('../views/Setup/TomorenView.vue'),
                    // },

                ]
            },
            {
                path: '/user.do/self',
                name: 'self',
                component: () =>
                    import ('../views/SelfView.vue'),
                children: [{
                        path: '/user.do/self',
                        name: 'self',
                        component: () =>
                            import ('../views/Self/SelfPwd.vue'),
                    },
                    {
                        path: '/user.do/self/selfpwd',
                        name: 'selfpwd',
                        component: () =>
                            import ('../views/Self/SelfPwd.vue'),
                    },
                    {
                        path: '/user.do/self/loginrec',
                        name: 'loginrec',
                        component: () =>
                            import ('../views/Self/LoginRecord.vue'),
                    },
                    {
                        path: '/user.do/self/warning',
                        name: 'warning',
                        component: () =>
                            import ('../views/Self/WarningView.vue'),
                    },
                ]
            },
            {
                path: '/user.do/clearhistory',
                name: 'clearhistory',
                component: () =>
                    import ('../views/ClearHistory.vue'),

            },
            {
                path: '/user.do/report',
                name: 'report',
                component: () =>
                    import ('../views/ReportView.vue'),

            }
        ]
    },

]

const router = createRouter({
        //process.env.BASE_URL
        history: createMemoryHistory(process.env.BASE_URL),

        routes
    })
    // router.beforeEach = (to, from) => {

// }

export default router