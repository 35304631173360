import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import Vue3Marquee from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
const app = createApp(App);
const pinia = createPinia();
app.use(ElementPlus, { locale: zhCn, });
app.use(Vue3Marquee);
app.use(router).use(pinia).mount('#app')